
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Query Details</h5>
      <div class="row g-4">
        <div class="col-md-8">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="">
                      <div class=""><strong>Status:</strong> <span v-html="statusBadge(item.status)"></span></div>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a @click="popupModalShow = true" class="dropdown-item"
                        href="javascript:void(0)" >
                          Update query </a>
                      </li>
                      <li>
                        <a @click.prevent="deleteItem()"
                        class="dropdown-item" href="javascript:void(0)">
                          Delete query </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-12"><h5 class="mb-1">{{item.subject}}</h5></div>
                    <div class="col-12">
                      <label class="form-label fw-bold">Query Message</label>
                      <div>{{item.description}}</div>
                    </div>
                    <div class="col-12"><small>Send At: {{$filters.date(item.created_at)}}</small></div>
                  </div>
                  <div v-if="item.response" class="row g-3">
                    <div class="col-12"><hr></div>
                    <div class="col-12">
                      <label class="form-label fw-bold">Response</label>
                      <div>{{item.response}}</div>
                    </div>
                    <div class="col-12"><small>Responded At: {{$filters.date(item.responded_at)}}</small></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-sticky" style="top: 90px;">
            <div class="card mb-4 overflow-hidden">
              <div class="card-body">
                <h5 class="mb-4">Sender</h5>
                <div v-if="item.sender">
                  <h6>{{item.sender.first_name}} {{item.sender.last_name}}</h6>
                  <p class="mb-0"><small>{{item.sender.email}}</small></p>
                </div>
                <div v-else>
                  <h6>System</h6>
                  <p class="mb-0"><small>This was sent authomatically </small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        <div v-if="item.recipient" class="col-md-4">
          <div class="card overflow-hidden">
            <div class="py-3" style="background-color: #87929a;">
            </div>
            <div class="card-body text-center">
              <a href="javascript:void(0)" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                <img :src="absoluteUrl(item.recipient.image)" class="avatar-img rounded-circle border border-4 border-card" alt="...">
              </a>
              <small>
                Recipient
              </small>
              <h3 class="card-title">
                <router-link :to="`/users/${item.recipient.id}`">{{item.recipient.first_name}} {{item.recipient.last_name}}</router-link>
              </h3>
              <p class="small text-muted mb-0">
                {{item.recipient.email}}
              </p>
            </div>
          </div>
        </div>
        <div v-if="item.court_case" class="col-md-4">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <h5 class="mb-3">Court case</h5>
              <h6><router-link :to="`/cases/${item.court_case.id}`">{{item.court_case.case_number}}</router-link></h6>
              <div v-if="item.court_case.status" class=""><strong>Status:</strong> <span v-html="statusBadge(item.court_case.status.status, item.status.color)"></span></div>
              <p class="mb-0"><small>{{item.court_case.parties}}</small></p>
            </div>
          </div>
        </div>
        <div v-if="item.attendance" class="col-md-4">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <h5 class="mb-4">Attendance</h5>
              <h6>{{case_stage.title}}</h6>
              <p><small>{{case_stage.description}}</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Update query" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
    <query-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="item" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import QueryForm from '@/components/forms/QueryForm.vue';

export default {
  name: "query-show",
  components:{
    IsLoading,
    QueryForm,
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      item: null
    }
  },
  watch: {
    '$route.params.queryId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/queries/${this.$route.params.queryId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete a query permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/queries/${this.item.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/queries'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

