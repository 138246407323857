<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-12">
                <label for="inputTitle">
                    Subject <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.subject"
                    :class="{ 'is-invalid': v$.data.subject.$error }"
                    id="inputTitle" name="subject" class="form-control"
                    placeholder="Enter subject" />
                <div v-for="(item, index) in v$.data.subject.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>   
            <div class="col-12">
                <label class="form-label">Query Message <span class="text-danger small">*</span></label>
                <textarea v-model="data.description" class="form-control"
                :class="{ 'is-invalid': v$.data.description.$error  }"
                rows="3" placeholder="Write query" name="description"></textarea>
                <div v-for="(item, index) in v$.data.description.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Send'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        pivotItem:{
            type: Object,
            default: null
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                subject: "",
                description:"",
            },
        }
    },
    validations() {
        return {        
            data:{
                subject: { required: required},           
                description: { required: required}           
            },

        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                formData.append('data', JSON.stringify(Object.assign(this.data, this.pivotItem)))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/queries/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.$store.commit("query/ADD_ITEM", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/queries/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updatedItem', response.data.data)
                            this.$store.commit("query/UPDATE_ITEM", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>